import React, { Component } from "react"
import { Link } from "gatsby"

import topmenuStyles from "../styles/scss/_topmenu.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

class Topmenu extends Component {
  render() {
    return (
      // ======= Top Menu ======= //
      <div className={` d-lg-block d-none ${topmenuStyles.top_menu}`}>
        <div className="container">
          <div className={`float-left`}>
            <ul className={`${topmenuStyles.left_side}`}>
              <li>
                <a
                  href="https://www.instagram.com/vangtry_technology/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/vangtrysoftware"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
              </li>
              <li>
                <a
                  href=" https://twitter.com/vangtry?lang=es"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </li>
            </ul>
          </div>
          <div className={`float-right`}>
            <ul className={`${topmenuStyles.right_side}`}>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=+573006247265"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} />
                  +57 3006247265
                </a>
              </li>
              <li>
                <Link to="/">
                  <FontAwesomeIcon icon={faEnvelope} />
                  admin@vangtry.com
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default Topmenu
