import React from "react"
import footerStyles from "../styles/scss/_footer.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

import {
  faInstagram,
  faFacebookF,
  faTwitter,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <footer
      className={`section_padding_100 text-center clearfix ${footerStyles.footer_section} ${footerStyles.footer_social_icon}`}
    >
      <div className={`${footerStyles.footer_social_icon}`}>
        <a
          href="https://www.instagram.com/vangtry_technology/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="2x" icon={faInstagram} />
        </a>

        <a
          href="https://www.facebook.com/vangtrysoftware"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="2x" icon={faFacebookF} />
        </a>

        <a
          href="https://twitter.com/vangtry?lang=es"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="2x" icon={faTwitter} />
        </a>

        <a
          href="https://api.whatsapp.com/send?phone=+573006247265"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon size="2x" icon={faWhatsapp} />
        </a>
      </div>
      <div className={`${footerStyles.footer_menu}`}>
        <nav>
          <ul className="p-0">
            <li className="title-text">
              <Link rel="noopener noreferrer" to="/about-us/">
                Nosotros.
              </Link>
            </li>
            <li className="paragpah-text">
              <Link rel="noopener noreferrer" to="/terms-conditions/">
                Términos &amp; Condiciones.
              </Link>
            </li>
          </ul>
        </nav>
      </div>

      <div className="copyright-text">
        <p>
          Copyright ©{new Date().getFullYear()}. Designed by{" "}
          <a
            href="https://vangtry.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            vangTry.
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
