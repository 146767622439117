import React, { Component } from "react"
import { Link } from "gatsby"

import { Nav, Navbar, Container } from "react-bootstrap"
import navbarStyles from "../styles/scss/_navabar.module.scss"
import SimpleMenu from "./simple-menu"
import Image from "../components/image"
import { AnchorLink } from "gatsby-plugin-anchor-links"

class Navigationbar extends Component {
  state = {
    isOpen: false,
  }

  render() {
    return (
      <div className={`${navbarStyles.header_area}`}>
        <div className={`${navbarStyles.main_menu}`}>
          <Navbar
            collapseOnSelect
            className={`w-100  ${navbarStyles.navbar}`}
            expand="lg"
            variant="dark"
            fixed="top"
          >
            <Container>
              <Navbar.Brand style={{ width: `120px` }}>
                <Link to="/">
                  <Image />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                className={`${navbarStyles.collapse_links}`}
              >
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <SimpleMenu className="simple-menu-vnt"></SimpleMenu>
                  <Link
                    activeClassName="navbar_active_link"
                    className={`${navbarStyles.nav_link}`}
                    to="/about-us/"
                  >
                    Nosotros
                  </Link>
                  <AnchorLink
                    className={`${navbarStyles.nav_link}`}
                    to="/#contact-us"
                    title="Contáctenos"
                  />
                  <Link
                    activeClassName="navbar_active_link"
                    partiallyActive={true}
                    className={`${navbarStyles.nav_link}`}
                    to="/blog/"
                  >
                    Blog
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </div>
    )
  }
}

export default Navigationbar
