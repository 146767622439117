import React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faCode,
  faStore,
  faDatabase,
  faMagic,
} from "@fortawesome/free-solid-svg-icons"
// We can inject some CSS into the DOM.
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
))

const StyledMenuItem = withStyles(theme => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root": {
        color: "#1b232e",
      },
    },
  },
}))(MenuItem)

export default function SimpleMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className="simple-menu-vnt"
      >
        Servicios
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem
          component={Link}
          to="/software-factory/"
          onClick={handleClose}
          children="SoftwareFactory"
        >
          <ListItemIcon>
            <FontAwesomeIcon size="1x" color="#1b232e" icon={faCode} />
          </ListItemIcon>
          <ListItemText primary="Software factory" />
        </StyledMenuItem>

        <StyledMenuItem
          component={Link}
          to="/digital-marketing/"
          onClick={handleClose}
          children="DigitalMarketing"
        >
          <ListItemIcon>
            <FontAwesomeIcon size="1x" color="#1b232e" icon={faMagic} />
          </ListItemIcon>
          <ListItemText primary="Marketing Digital" />
        </StyledMenuItem>

        <StyledMenuItem
          component={Link}
          to="/database/"
          onClick={handleClose}
          children="Database"
        >
          <ListItemIcon>
            <FontAwesomeIcon size="1x" color="#1b232e" icon={faDatabase} />
          </ListItemIcon>
          <ListItemText primary="Database" />
        </StyledMenuItem>
        <StyledMenuItem
          component={Link}
          to="/tienda-en-linea/"
          onClick={handleClose}
          children="TiendaEnLinea"
        >
          <ListItemIcon>
            <FontAwesomeIcon size="1x" color="#1b232e" icon={faStore} />
          </ListItemIcon>

          <ListItemText primary="Tienda en línea" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  )
}
